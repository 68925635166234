import { Action } from '@ngrx/store';
import { Provider, SysAlert } from '../_models/avenue99.user.model';

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    UpdateAccount = '[Auth] Update Account',
    UpdatePassword = '[Auth] Update Password',
    UpdateCompany = '[Auth] Update Company Details',
    ToggleLoading = '[Auth] Toggle Loader',
    UserWasLoaded = '[Auth] User Was Loaded',
    AutoLogin = '[Auth] Auto Login',
    RemoveAlertForUser = '[Auth] Remove Alert',
    CreateAlertForUser = '[Auth] Create Alert',
    Register = '[Auth] Register new account',
    AutoLoadWasInited = '[Auth] Init Auth Was Done (Master)'
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { email: string, password: string }){ }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class UpdateAccount implements Action {
    readonly type = AuthActionTypes.UpdateAccount;
    constructor(public payload: { email: string, name: string }){ }
}

export class UpdatePassword implements Action {
    readonly type = AuthActionTypes.UpdatePassword;
    constructor(public payload: { oldPassword: string, password: string }){ }
}

export class UpdateCompany implements Action {
    readonly type = AuthActionTypes.UpdateCompany;
    constructor(public payload:  Provider, public isNew: boolean = false){ }
}

export class ToggleLoading implements Action {
    readonly type = AuthActionTypes.ToggleLoading;
    constructor(public payload: boolean){ }
}

export class UserWasLoaded implements Action {
    readonly type = AuthActionTypes.UserWasLoaded
    constructor(public payload: any){}
} 

export class AutoLogin implements Action {
    readonly type = AuthActionTypes.AutoLogin;
}

export class CreateAlertForUser implements Action {
    readonly type = AuthActionTypes.CreateAlertForUser;
    constructor(public payload: SysAlert){}
}

export class RemoveAlertForUser implements Action {
    readonly type = AuthActionTypes.RemoveAlertForUser;
}

export class Register implements Action {
    readonly type = AuthActionTypes.Register;
    constructor(public payload: any){}
}

export class AutoLoadWasInited implements Action {
    readonly type = AuthActionTypes.AutoLoadWasInited;
}

export type AuthActions = AutoLoadWasInited | Register | RemoveAlertForUser | CreateAlertForUser | Login | Logout | ToggleLoading | UpdateCompany | UpdatePassword | UpdateAccount | UserWasLoaded | AutoLogin;
