// NGRX
import { createSelector } from '@ngrx/store';

export const selectAuthState = state => state.auth;

export const MasterDone = createSelector(
    selectAuthState,
    auth => auth.masterDone
);

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => auth.loggedIn
);

export const isLoggedOut = createSelector(
    isLoggedIn,
    loggedIn => !loggedIn
);

export const IsLoading = createSelector(
    selectAuthState,
    auth => auth.loading
)

export const currentAuthToken = createSelector(
    selectAuthState,
    auth => auth.authToken
);

export const isUserLoaded = createSelector(
    selectAuthState,
    auth => auth.isUserLoaded
);

export const currentUser = createSelector(
    selectAuthState,
    auth => auth.user
);

export const ___Alert = createSelector(
    selectAuthState,
    auth => auth.alert
);
