// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	backend: 'https://anticovid.3lsaree3.com/api/provider/v1/',
	appBackend: 'https://anticovid.3lsaree3.com/api/v1/',
	// backend: 'http://localhost:8000/api/provider/v1/',
	// appBackend: 'http://localhost:8000/api/v1/',
	storageBucket: {
		apiKey: "AIzaSyBu2PBIOsktNS_IzRVhTmMI7QAEHUNMU4Y",
		authDomain: "avenue99-e0b80.firebaseapp.com",
		databaseURL: "https://avenue99-e0b80.firebaseio.com",
		projectId: "avenue99-e0b80",
		storageBucket: "avenue99-e0b80.appspot.com",
		messagingSenderId: "49274034703",
		appId: "1:49274034703:web:da88dd0e16eda1817be17a"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
