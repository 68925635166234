import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient) {}

    login(data: {email: string, password: string;}): Observable<any>{
        return this.http.post(environment.backend+'auth/login', data);
    }

    RegisterNewProvider(data: any): Observable<any> {
        return this.http.post(environment.backend+'auth/register', data);
    }

    getUserByToken(): Observable<any> {
        return this.http.get(environment.backend+'auth/login');
    }

    updateProvider(data: any, isNew: boolean = false): Observable<any> {
        if(isNew)
            return this.http.post(environment.backend+'provider/create/profile', data);

        return this.http.post(environment.backend+'provider/update/profile', data);
    }

    updateAccount(data): Observable<any> {
        return this.http.post(environment.backend+'provider/account/update', data);
    }

    updatePassword(data): Observable<any> {
        return this.http.post(environment.appBackend+'auth/changePass', data);
    }

    getCategories(): Observable<any> {
        return this.http.get(environment.appBackend+'loadSelectOptionGategories');
    }

}
