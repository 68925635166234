// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import { isLoggedIn, MasterDone } from '../_selectors/auth.selectors';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let res;
        const userToken = localStorage.getItem(environment.authTokenKey);
        if(!userToken){
            console.log('guard handler');
            //this.router.navigateByUrl('/auth/login'); 
            res = false;
        }else{
            res = true;
        }
        this.store.pipe(
            select(MasterDone),
            tap(
                (res) => {
                    console.log(res);
                    if(res && !userToken){
                        this.router.navigateByUrl('/auth/login');
                    }
                }
            )
        ).subscribe();
        // this.store.pipe(
        //     select(MasterDone),
        //     tap((res) => {
        //         if(res) {
        //             this.store
        //             .pipe(
        //                 select(isLoggedIn),
        //                 tap(loggedIn => {
        //                     res = loggedIn;
        //                     if (!loggedIn) {
        //                         this.router.navigateByUrl('/auth/login');
        //                     }
        //                 })
        //             );
        //         }else{
        //             const userToken = localStorage.getItem(environment.authTokenKey);
        //             if(!userToken){
        //                 this.router.navigateByUrl('/auth/login'); 
        //                 res = false;
        //             }else{
        //                 res = true;
        //             }
        //         }
        //     })
        // );
        // console.log(res);
        return res;
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigateByUrl('/auth/login');
                    }
                })
            );
        //return true;
    }
}
