// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User, SysAlert } from '../_models/avenue99.user.model';

export interface AuthState {
    loggedIn: boolean;
    authToken: string;
    user: User;
    loading: boolean;
    isUserLoaded: boolean;
    alert: SysAlert;
    masterDone: boolean;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    loading: false,
    isUserLoaded: false,
    alert: null,
    masterDone: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.UserWasLoaded: {
            return {
                ...state,
                loggedIn: true,
                user: action.payload.user,
                authToken: action.payload.accessToken,
                isUserLoaded: true,
            }
        }

        case AuthActionTypes.AutoLoadWasInited: {
            return {
                ...state,
                masterDone: true
            }
        }

        case AuthActionTypes.CreateAlertForUser: {
            return {
                ...state,
                alert: action.payload
            }
        }

        case AuthActionTypes.RemoveAlertForUser: {
            return {
                ...state,
                alert: null
            }
        }

        case AuthActionTypes.ToggleLoading: {
            return {
                ...state, 
                loading: action.payload
            }
        }

        case AuthActionTypes.UserWasLoaded: {
            return {
                ...state,
                loading: false,
                isUserLoaded: true,
                loggedIn: true,
                authToken: action.payload.accessToken,
                user: action.payload.user
            }
        }

        case AuthActionTypes.Logout: {
            return {
                ...state,
                loggedIn: false,
                isUserLoaded: false,
                authToken: null,
                user: undefined
            }
        }

        default:
            return state;
    }
}
