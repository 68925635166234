export class PageConfig {
	public defaults: any = {
		dashboard: {
			page: {
				title: 'Dashboard',
				desc: 'Latest updates and statistic charts'
			},
		},
		profile: {
			page: {title: 'Account & Company Profile', desc: ''},
			edit: {
				company: {
					page: {title: 'Update Company Profile', desc: 'Help us to promote your business'}
				},
				account: {
					page: {title: 'Update account details', desc: 'Secure your account and login methods'}
				}
			}
		},
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: {title: '403 Access Forbidden', desc: '', subheader: false}
			}
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
