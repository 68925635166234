// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { tap, finalize } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, AutoLogin, ToggleLoading, UserWasLoaded, UpdateCompany, CreateAlertForUser, UpdateAccount, UpdatePassword, Register, AutoLoadWasInited } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { SysAlert } from '../_models/avenue99.user.model';

@Injectable()
export class AuthEffects {

    @Effect({dispatch: false})
    UpdateProviderProfile$ = this.actions$.pipe(
        ofType<UpdateCompany>(AuthActionTypes.UpdateCompany),
        tap(
            (action) => {
                console.log(action.payload);
                this.updateCompany(action.payload, action.isNew);
            }
        )
    );

    @Effect({dispatch: false})
    autoLogin$ = this.actions$.pipe(
        ofType<AutoLogin>(AuthActionTypes.AutoLogin),
        tap(
            () => {
                this.store.dispatch(new ToggleLoading(true));
                this.auth.getUserByToken().subscribe(
                    (res) => {
                        console.log(res);
                        this.store.dispatch(new UserWasLoaded(res));
                        this.store.dispatch(new ToggleLoading(false));
                    },
                    (err) => {
                        console.log(err);
                    }
                )
            }
        )
    );

    @Effect({dispatch: false})
    UpdateAccount$ = this.actions$.pipe(
        ofType<UpdateAccount>(AuthActionTypes.UpdateAccount),
        tap(
            (action) => {
                this.updateAccountDetails(action.payload);
            }
        )
    )

    @Effect({dispatch: false})
    UpdatePassword$ = this.actions$.pipe(
        ofType<UpdatePassword>(AuthActionTypes.UpdatePassword),
        tap(
            (action) => {
                this.updatePassword(action.payload);
            }
        )
    )

    @Effect({dispatch: false})
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            this.store.dispatch(new ToggleLoading(true));
            this.auth.login(action.payload).subscribe(
                (res) => {
                    console.log(res);
                    this.store.dispatch(new UserWasLoaded(res));
                    this.store.dispatch(new ToggleLoading(false));
                },
                (err) => {
                    console.log(err);
                    this.store.dispatch(new ToggleLoading(false));
                }
            )
        }),
    );

    @Effect({dispatch: false})
    register$ = this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap(
            (action) => {
                this.RegisterNewProvider(action.payload);
            }
        )
    );

    @Effect({dispatch: false})
    UserWasLoaded$ = this.actions$.pipe(
        ofType<UserWasLoaded>(AuthActionTypes.UserWasLoaded),
        tap(
            (action) => {
                this.store.dispatch(new AutoLoadWasInited());
                localStorage.setItem(environment.authTokenKey, action.payload.accessToken);
                //this.router.navigateByUrl('');
            }
        )
    );

    @Effect({dispatch: false})
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
            localStorage.removeItem(environment.authTokenKey);
            console.log('logout handler');
            this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}});
        })
    );

    @Effect()
    init$: Observable<Action> = defer(() => {
        const userToken = localStorage.getItem(environment.authTokenKey);
        let observableResult = of({type: 'NO_ACTION'});
        if (userToken) {
            observableResult = of(new AutoLogin());
        }else{
            this.store.dispatch(new AutoLoadWasInited());
        }
        return observableResult;
    });

    private returnUrl: string;

    constructor(private actions$: Actions,
                private router: Router,
                private auth: AuthService,
                private store: Store<AppState>) {

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
    }

    RegisterNewProvider(data){
        this.store.dispatch(new ToggleLoading(true));
        this.auth.RegisterNewProvider(data).pipe(
            finalize(() => {
                this.store.dispatch(new ToggleLoading(false));
            })
        ).subscribe(
            (res) => {
                this.store.dispatch(new UserWasLoaded(res));
                this.router.navigateByUrl('/profile/edit/company')
                console.log(res);
            },
            (err) => {
                const alert: SysAlert = {
                    closeAble: true,
                    msg: 'Something went wrong!',
                    title: ' ',
                    type: 'danger'
                } 
                this.store.dispatch(new CreateAlertForUser(alert));
                console.log(err);
            }
        )
    }

    updateAccountDetails(data){
        this.store.dispatch(new ToggleLoading(true));
        this.auth.updateAccount(data).pipe(
            finalize(() => {
                this.store.dispatch(new ToggleLoading(false));
            })
        ).subscribe(
            (res) => {
                console.log(res);
                const alert: SysAlert = {
                    closeAble: true,
                    msg: 'Account details updated successfuly!',
                    title: ' ',
                    type: 'success'
                } 
                this.store.dispatch(new CreateAlertForUser(alert));
            },
            (err) => {
                const alert: SysAlert = {
                    closeAble: true,
                    msg: 'Something went wrong! please try again later',
                    title: ' ',
                    type: 'danger'
                } 
                console.log(err);
                this.store.dispatch(new CreateAlertForUser(alert));
            }
        )
    }

    updatePassword(data){
        this.store.dispatch(new ToggleLoading(true));
        this.auth.updatePassword(data).pipe(
            finalize(() => {
                this.store.dispatch(new ToggleLoading(false));
            })
        ).subscribe(
            (res) => {
                console.log(res);
                const alert: SysAlert = {
                    closeAble: true,
                    msg: 'Password updated successfuly!',
                    title: ' ',
                    type: 'success'
                } 
                this.store.dispatch(new CreateAlertForUser(alert));
            },
            (err) => {
                let alert: SysAlert;
                if(err.status == 401){
                    alert = {
                        closeAble: true,
                        msg: 'Old password was wrong.',
                        title: ' ',
                        type: 'danger'
                    } 
                }else{
                    alert = {
                        closeAble: true,
                        msg: 'Something went wrong! please try again later',
                        title: ' ',
                        type: 'danger'
                    } 
                }
                
                console.log(err);
                this.store.dispatch(new CreateAlertForUser(alert));
            }
        )
    }
    
    updateCompany(data, isNew: boolean = false) {
        this.store.dispatch(new ToggleLoading(true));
        this.auth.updateProvider(data, isNew).pipe(
            finalize(() => {
                this.store.dispatch(new ToggleLoading(false));
            })
        ).subscribe(
            (res: any) => {
                //alert(res);
                const alert: SysAlert = {
                    closeAble: true,
                    msg: 'Company details updated successfuly !',
                    title: ' ',
                    type: 'success'
                } 
                this.store.dispatch(new CreateAlertForUser(alert));
            },
            (err) => {
                console.log(err);
                const alert: SysAlert = {
                    closeAble: true,
                    msg: 'Something went wrong! please try again later',
                    title: ' ',
                    type: 'danger'
                } 
                this.store.dispatch(new CreateAlertForUser(alert));
            }
        )
    }

}
